import firebase from "firebase/compat/app";
import "firebase/compat/auth";
require("firebase/firestore");
import { db } from "../../src/main.js";
import store from "../../src/store/store.js";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

export const getUserData = async (uid) => {
  const docRef = doc(db, "users", uid);
  const response = await getDoc(docRef);

  if (response) {
    return response.data();
  } else {
    console.log("getUserData error");
  }
};

export const verifyUserExists = async (email) => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const response = await getDocs(q);
  if (!response.empty) {
    return true
  } else {
    console.log("verifyUserExists error");
    return false
  }
};

export const getAllUsers = async () => {
  const q = query(collection(db, "users"));
  const response = await getDocs(q);
  const users = [];
  response.forEach((doc) => {
    users.push(doc.data());
  });
  return users;
};

export const validateUser = async () => {
  return new Promise((resolve) => {
    // this adds a hook for the initial auth-change event
    firebase.auth().onAuthStateChanged((user) => {
      if (user == null || user.isAnonymous) {
        return false;
      } else {
        store.commit("setUser", user);
      }

      resolve(user.multiFactor.user);
    });
  });
};

export const setUserHireDate = async (id, hireDate) => {
  await updateDoc(doc(db, "users", id), {
    hireDate,
  });
};
