import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  user: null
}

const mutations = {
    setUser(state, value) {
      state.user = value
    }
  }

//   const actions = {
//     getUser({commit, state}) {
      
//     }
//   }



const store = new Vuex.Store({
  state,
  mutations
})

export default store