import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { getFirestore } from "firebase/firestore";
import store from "./store/store";
import { validateUser } from "../api/endpoints/user.js";
import { getUserData } from "../api/endpoints/user";
import VModal from "vue-js-modal/dist/ssr.nocss";
import "vue-js-modal/dist/styles.css";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail
} from "firebase/auth";


Vue.use(require("vue-moment"));

Vue.use(VueRouter);
Vue.use(VModal, { componentName: "vModal" });

// Routers
const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes: [
    {
      path: "/",
      alias: ["/index.html"],
      name: "home",
      component: () => import("./components/Home.vue"),
    },
    {
      path: "/emailRedirect",
      name: "emailRedirect",
      props: true,
      component: () => import("./components/EmailRedirect.vue"),
      beforeEnter: (to, from, next) => {
        // if not logged in, check to see if this is a login attempt from an email link
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem("emailForSignIn");
          if (!email) {
            // if something went wrong setting the email on sign in, we can have them confirm it here.
            // this will also prevent bogus login attempts
            email = window.prompt("Please provide your email for confirmation");
          }
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              // go to the dahsboard and send the user in the params
              to.params.user = result.user;
              next(to.params);
              // Clear email from storage.
              window.localStorage.removeItem("emailForSignIn");
            })
            .catch((error) => {
              console.log("error", error);
              next("/");
            });
        } else {
          next("/");
        }
      },
    },
    {
      path: "/assessment",
      name: "assessment",
      component: () => import("./components/TheAssessment.vue"),
      props: true,
      beforeEnter: (to, from, next) => {
        validateUser().then((user) => {
          if (user) {
            next();
          } else {
            next("/");
          }
        });
      },
    },
    {
      path: "/assessmentOverview",
      name: "assessmentOverview",
      component: () => import("./components/AssessmentOverview.vue"),
      props: true,
      beforeEnter: (to, from, next) => {
        validateUser().then((user) => {
          if (user) {
            next();
          } else {
            next("/");
          }
        });
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./components/Dashboard.vue"),
      props: true,
      beforeEnter: (to, from, next) => {
        validateUser().then((user) => {
          if (user) {
            next();
          } else {
            next("/");
          }
        });
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("./components/Admin.vue"),
      props: true,
      beforeEnter: (to, from, next) => {
        validateUser().then((user) => {
          if (user) {
            let adminUser = null;
            getUserData(user.uid).then((u) => {
              adminUser = u;
              if (adminUser.isAdmin) {
                next();
              } else {
                next("/dashboard");
              }
            });
          } else {
            next("/");
          }
        });
      },
    },
  ],
});

// Database connection
import firebase from "firebase/compat/app";

import vuetify from './plugins/vuetify'

const firebaseConfig = {
  apiKey: "AIzaSyDtaQBGkj36N82GEsNsvE4gUm9fey4V30Y",
  authDomain: "tpi-team-member-assessment.firebaseapp.com",
  projectId: "tpi-team-member-assessment",
  storageBucket: "tpi-team-member-assessment.appspot.com",
  messagingSenderId: "458546373065",
  appId: "1:458546373065:web:546adfe5e3fef496fdc818"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const storedUser = localStorage.getItem("userId");

Vue.config.productionTip = false;

async function login() {
  const actionCodeSettings = {
    url: `https://${window.location.host}/emailRedirect`,
    // This must be true.
    handleCodeInApp: true,
  };


  const auth = await getAuth();

  sendSignInLinkToEmail(auth, 'chris+tpi@mellor.app', actionCodeSettings)
  console.log('dent')

}

async function keepEmailAlive()
{
    var hour = new Date().getHours();
    var mins = new Date().getMinutes();
    var seconds = new Date().getSeconds();
    if((hour == "11" || hour == "00" || hour == "0") && mins == "00" && seconds == "00"){
      await login()
     }
}

setInterval(function() {  keepEmailAlive(); }, 1000);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
