<template>
  <v-app>
    <div v-if="user" style="display: flex; justify-content: flex-end; height: 0px;">
      <div style="width: 250px">
        <v-menu offset-y style="width: 250px">
          <template v-slot:activator="{ on, attrs }">
            <div style="display: flex; justify-content: flex-end">
              <v-icon style="margin: 25px" v-bind="attrs" v-on="on"
                >mdi-account</v-icon
              >
            </div>
            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> Dropdown </v-btn> -->
          </template>
          <v-list>
            <v-list-item
              v-if="thisUser && thisUser.isAdmin"
              style="cursor: pointer"
            >
              <v-list-item-title @click="goToDashboard">{{
                dashboardText
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer">
              <v-list-item-title @click="signOut">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </v-app>
</template>
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { mapState } from "vuex";
import { getUserData } from "../api/endpoints/user";

export default {
  computed: {
    ...mapState(["user"]),
    dashboardText() {
      if (this.routeName?.includes("admin")) {
        return "User Dashboard";
      } else {
        return "Admin Dashboard";
      }
    },
    routeName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      thisUser: null,
    };
  },
  methods: {
    getUserData,
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/");
        });
    },
    goToDashboard() {
      if (this.routeName?.includes("admin")) {
        this.$router.push({
          path: "/dashboard",
          query: { userId: this.thisUser.id },
        });
      } else {
        this.$router.push({
          path: "/admin",
          query: { userId: this.thisUser.id },
        });
      }
    },
  },
  watch: {
    async user(val) {
      const user = await this.getUserData(val.uid);
      this.thisUser = user;
    },
  },
};
</script>
